@use "sass:color";@import "@skryv/core-ng1/theme/includes/index";
.wrp-factuurcontrole-overview {
  .label-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;

    .sorting-label {
      font-size: $smallest-font-size;
      color: $gray-lighter;
      margin-bottom: 8px; // to align with label
      font-weight: $font-weight-light;
      cursor: pointer;

      .icon {
        margin-right: calc($base-spacing/2);

        &.skr-icon-cancel {
          font-size: 0.8 * $smallest-font-size;
        }
      }
    }
  }

  table {
    width: 100%;

    th,
    td {
      border-bottom: 0;
      font-size: 0.9 * $base-font-size;
      font-weight: $font-weight-normal;
      vertical-align: center;
      padding: 0;

      > a.invisible-a-tag-for-right-click {
        display: block; // makes the anchor grow to the size of the table cell, so the full cell is clickable
        color: $base-font-color;

        // add padding on a-tag instead of wrapping td-tag. This is to maximise the right-clickable-space
        padding: 0 $base-spacing 0 calc($base-spacing/2);
      }
    }

    th {
      &.totaal,
      &.afgekeurd {
        text-align: right;

        > label > .icon {
          padding-right: 0;
        }
      }

      &.isolatie,
      &.afgekeurd,
      &.comment,
      &.asbest {
        text-align: center;
      }

      > label {
        margin-bottom: 0;
        padding-right: $base-spacing;

        > .icon {
          font-size: 0.875 * $base-font-size;
          margin-left: 0;
          margin-right: 0;
          padding: 0 $base-spacing 0 calc($base-spacing/2);
        }
      }
    }

    tbody:hover {
      background-color: $gray-light;
    }

    .base-content-wrapper:hover {
      color: $base-font-color;
      cursor: pointer;
    }

    .base-content-wrapper {
      > td {
        .icon {
          &.comment-icon {
            margin-right: 0;
          }
          &.category-icon {
            font-size: 0.95 * $base-font-size;
            margin-right: 0.2 * $base-spacing;
          }
        }
        &.percentage {
          font-weight: $font-weight-light;
          text-align: right;
        }
        &.currency,
        &.percentage {
          text-align: right;
        }
      }

      > td:last-child {
        background-color: white;
      }
    }

    .base-content-wrapper:hover ~ .extra-content-wrapper {
      display: table-row;
    }

    .extra-content-wrapper {
      display: none;
      width: 100%;

      > td {
        display: table-cell;

        > .extra-content-container {
          border-top: solid $gray-lighter 1px;

          padding-left: $base-spacing;
          padding-top: calc($base-spacing/2);
          margin-top: calc($base-spacing/2);
          margin-bottom: calc($base-spacing/2);

          font-weight: $font-weight-light;
          font-size: 0.7 * $base-font-size;

          > .extra-content-categories {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-align: center;

            > div {
              margin-top: 0;
              display: block;

              &.technisch_ok {
                color: $gray-dark;
              }
              &.technisch_afgekeurd {
                color: $gray-lighter;
              }
              &.afgewezen {
                color: $red;
              }
              &.aangenomen {
                color: $green;
              }
            }
          }

          > .extra-content-comment {
            .icon {
              margin-right: $base-spacing;
            }
          }
        }
      }

      .extra-info-icon {
        margin-right: calc($base-spacing/4);
      }

      .extra-info-label:last-child {
        margin-right: $base-spacing;
      }
    }

    .extra-content-wrapper:hover {
      display: table-row;
    }
  }
}
