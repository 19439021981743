@use "sass:color";@import "@skryv/core-ng1/theme/includes/index";
wrp-technische-controle-summary {
  height: 100%;

  .extended-summary {
    z-index: $navigation-sidebar-z-index;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: calc($base-spacing/ 4);

    position: absolute;
    left: 0px;
    
    transition: bottom 0.25s;
    transition-timing-function: ease-out;

    border: $base-border;

    &.extended-summary-open {
      bottom: 0px;
    }

    &.extended-summary-closed {
      bottom: calc(-250px - (#{$base-spacing}/4));
    }

    .extended-summary-circles {
      padding: $base-spacing;
    }
  }

  .backdrop {
    z-index: calc(#{$navigation-sidebar-z-index} - 100);
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
  }  
}
