@use "sass:color";@import "@skryv/core-ng1/theme/includes/index";
wrp-factuurcontrole-summary {
  height: 100%;

  .extended-summary {
    overflow-y: auto;
    max-height: calc(100vh - $footer-height - $navigation-height);
    z-index: $navigation-sidebar-z-index;

    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: $base-border;

    margin: calc($base-spacing/ 4);

    transform-origin: 100% 100%;
    transform: scale(1, 0);
    transition: transform ease 250ms;

    position: fixed;
    left: 0;
    bottom: $footer-height;

    &.extended-summary-open {
      transform: scale(1, 1);
    }

    button.premieberekening-toggle {
      border-radius: $base-spacing * 0.25;
      padding: $base-spacing;
      margin: calc($base-spacing/ 4);

      &:hover {
        background-color: $gray-light !important;
      }
    }
  }

  .circles-and-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .backdrop {
    z-index: calc(#{$navigation-sidebar-z-index} - 100);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
