@use "sass:color";@import "@skryv/core-ng1/theme/includes/index";
wrp-factuurcontrole-summary-extended {
  .extended-summary-circles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    padding: $base-spacing;

    .circle-tile {
      width: 100%;
    }

    > .tile {
      flex-grow: 1;
      padding-top: 0;
    }
  }

  .tile-content {
    border: 1px solid darken($gray-light, 5);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &.general-info {
      .info-title {
        margin-bottom: calc($base-spacing/2);
        text-align: center;
      }
      .info-items {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: calc($base-spacing/2);
        align-items: center;
        align-content: center;

        p {
          font-weight: $font-weight-light;
          font-size: $base-font-size;
        }
      }
    }

    &.circle-disabled {
      color: $gray;
      opacity: 0.9;
    }

    .circle-progress {
      margin: auto;
      margin-bottom: $base-spacing;
    }

    .circle-labels {
      font-weight: $font-weight-light;
      font-size: 0.8 * $base-font-size;

      margin-top: $base-spacing;

      // margin between thumbs icon and amount
      .circle-approved > span,
      .circle-disapproved > span {
        margin-right: calc($base-spacing/2);
      }

      .circle-disapproved > span:not(:first-of-type) {
        margin-left: calc($base-spacing/2);
      }

      // special ways to display text
      .circle-approved,
      .circle-total {
        font-weight: $font-weight-normal;
        font-size: $base-font-size;
      }
    }

    &.circle-tile {
      text-align: center;

      .circular-progress {
        margin-top: 20px;
      }

      // these two 'categories' should be displayed differently than the 'normal' ones
      &.totaal_gevalideerd,
      &.totaal_premie {
        .circle-approved,
        .circle-total {
          font-weight: $font-weight-normal;
          font-size: 1.2 * $base-font-size;
        }

        // larger circular progress bars
        .circular-progress {
          height: 80px;
          width: 80px;
          margin-top: 0;

          .circular-progress-inner {
            line-height: 80px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .circular-progress {
    .circular-progress-inner .category-icon {
      margin-bottom: 8px;
    }
  }

  .circle-icon {
    line-height: inherit;
  }
}

.info-tooltip {
  pointer-events: none;
  &.info-tooltip .tooltip-inner {
    border-radius: 4px;
    background-color: #2b2b2b;
    opacity: 0.9;
  }

  &.info-tooltip .tooltip-arrow {
    display: none;
  }
}
